<template>
  <v-card>
    <v-toolbar flat dense>
      <v-spacer />
      <v-toolbar-items>
        <v-btn small="" :disabled="sendingEmail || staff.email === null || staff.email === undefined"
          :loading="sendingEmail" text @click="sendEmail">
          {{ $t('EMAIL') }}
        </v-btn>
        <v-btn small="" :disabled="exportingPDF" :loading="exportingPDF" text @click="exportPDF">
          {{ $t('pdf') }}
        </v-btn>
        <v-btn small="" :disabled="exportingXLS" :loading="exportingXLS" text @click="exportXLS">
          {{ $t('xls') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div :ref="refKey">
      <v-simple-table>
        <template>
          <tbody>
            <tr>
              <td>
                {{ transliterate(staff.address.name_line) }}
              </td>
              <td class="text-right">
                {{ captionFormatted }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table class="mb-0" :ref="refKey">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('day') }}
              </th>
              <th class="text-left">
                {{ $t('clockIn') }}
              </th>
              <th class="text-left">
                {{ $t('clockOut') }}
              </th>
              <th class="text-left">
                {{ $t('dayHours') }}
              </th>
              <th class="text-left">
                {{ $t('eveningHours') }}
              </th>
              <th class="text-left">
                {{ $t('weekendHours') }}
              </th>
              <th class="text-left">
                {{ $t('publicHolidayInitials') }}
              </th>
              <th class="text-left">
                {{ $t('majorPublicHolidayInitials') }}
              </th>
              <th class="text-left">
                {{ $t('totalHours') }}
              </th>
              <th class="text-left">
                {{ $t('type') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, index) in work" :key="index" @dblclick="editWork(log)">
              <td>{{ formatDate(log.start_date, "dddd") }}</td>
              <td>{{ formatDate(log.start_date, "YYYY/MM/DD HH:mm:ss") }}</td>
              <td>{{ formatDate(log.end_date, "YYYY/MM/DD HH:mm:ss") }}</td>
              <td>
                {{ log.standard_hours * 1 }}
              </td>
              <td>
                {{ log.evening_hours * 1 }}
              </td>
              <td>
                {{ log.weekend_hours * 1 }}
              </td>
              <td>
                {{ log.public_holiday_hours * 1 }}
              </td>
              <td>
                {{ log.festive_hours * 1 }}
              </td>
              <td>
                {{ log.total_hours * 1 }}
              </td>
              <td>
                {{ $t(log.type) }}
              </td>
            </tr>
            <tr class="font-weight-bold grey lighten-2">
              <td>{{ $t('totals') }}</td>
              <td />
              <td />
              <td>
                {{getSum(work.map(l => l.standard_hours))}}
              </td>
              <td>
                {{getSum(work.map(l => l.evening_hours))}}
              </td>
              <td>
                {{getSum(work.map(l => l.weekend_hours))}}
              </td>
              <td>
                {{getSum(work.map(l => l.public_holiday_hours))}}
              </td>
              <td>
                {{getSum(work.map(l => l.festive_hours))}}
              </td>
              <td>
                {{getSum(work.map(l => l.total_hours))}}
              </td>
              <td>

              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'

import { transliterate as tr } from 'transliteration'

export default {
  name: 'WorkHoursDetailedTable',
  props: {
    caption: {
      type: String,
      default: ''
    },
    work: {
      type: Array,
      default: () => []
    },
    staff: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      exportingXLS: false,
      exportingPDF: false,
      sendingEmail: false
    }
  },
  computed: {
    defaultTimeZone() {
      return this.$store.state.organization?.defaultTimeZone || 'UTC'
    },
    captionFormatted() {

      if (this.caption.includes('|')) {

        let caption = ''
        const captionArray = this.caption.split('|')

        if (captionArray[0]) {
          caption += this.$moment(new Date(parseInt(captionArray[0])))
            .utcOffset(moment.tz(this.defaultTimeZone).utcOffset())
            .format('DD-MM-YYYY')
        }

        caption += ' - '

        if (captionArray[1]) {
          caption += this.$moment(new Date(parseInt(captionArray[1])))
            .utcOffset(moment.tz(this.defaultTimeZone).utcOffset())
            .format('DD-MM-YYYY')
        }
        else {
          caption += this.$moment()
            .utcOffset(moment.tz(this.defaultTimeZone).utcOffset())
            .format('DD-MM-YYYY')
        }

        return caption
      }

      return this.caption
    },
    refKey: function () {
      return 'WorkHoursDetailedTable:' + this.caption
    }
  },
  methods: {
    transliterate(value) {
      return tr(value)
    },
    editWork(log) {
      this.$emit('editWork', log)
    },
    getSum(valueArray) {
      return this.$store.getters.getSum(valueArray)
    },
    sendEmail: function () {
      this.sendingEmail = true
      const html = this.$refs[this.refKey].innerHTML

      this.$store.dispatch('emailPDF', {
        fileName: this.refKey,
        html: html,
        recipient: this.staff.email,
        subject: this.$t('workHoursSummary')
      })

      setTimeout(() => {
        this.sendingEmail = false
      }, 2000)

    },
    exportXLS: function () {
      this.exportingXLS = true
      const html = this.$refs[this.refKey].innerHTML

      this.$store.commit('exportStore/exportXLS', {
        fileName: this.refKey,
        html: html
      })

      setTimeout(() => {
        this.exportingXLS = false
      }, 2000)

    },
    exportPDF: function () {
      this.exportingPDF = true
      const html = this.$refs[this.refKey].innerHTML
      this.$store.commit('exportPDF', {
        fileName: this.refKey,
        html: html
      })

      setTimeout(() => {
        this.exportingPDF = false
      }, 2000)
    },
    formatDate(date, format) {
      return this.$moment(date)
        .utcOffset(moment.tz(this.defaultTimeZone).utcOffset())
        .format(format)
    }
  }
}
</script>
